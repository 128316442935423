import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BooksPage = () => {

  return (
    <Layout >
      <SEO title="Books" />
      <h1>Books </h1>
      <p></p>
    </Layout>
  )
}

export default BooksPage